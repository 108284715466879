/* josefin-sans-100normal - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: 
    url('./files/josefin-sans-all-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-100italic - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: 
    url('./files/josefin-sans-all-100-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-100-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-200normal - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: 
    url('./files/josefin-sans-all-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-200italic - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: 
    url('./files/josefin-sans-all-200-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-200-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-300normal - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: 
    url('./files/josefin-sans-all-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-300italic - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: 
    url('./files/josefin-sans-all-300-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-300-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-400normal - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: 
    url('./files/josefin-sans-all-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-400italic - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: 
    url('./files/josefin-sans-all-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-500normal - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: 
    url('./files/josefin-sans-all-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-500italic - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: 
    url('./files/josefin-sans-all-500-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-500-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-600normal - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: 
    url('./files/josefin-sans-all-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-600italic - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: 
    url('./files/josefin-sans-all-600-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-600-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-700normal - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: 
    url('./files/josefin-sans-all-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* josefin-sans-700italic - all */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: 
    url('./files/josefin-sans-all-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/josefin-sans-all-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
